



export function esPropietario(): boolean {
    if (localStorage.getItem("usuario")) {
        var user = JSON.parse(localStorage.getItem("usuario"));
        return +user.rol == 1;
    }
}


export function esTaller(): boolean {
    if (localStorage.getItem("usuario")) {
        var user = JSON.parse(localStorage.getItem("usuario"));
        return +user.rol == 2;
    }
}

export function esAnalista(): boolean {
    if (localStorage.getItem("usuario")) {
        var user = JSON.parse(localStorage.getItem("usuario"));
        return +user.rol == 3;
    }
}

export function checkVencido(componente: any): boolean {
    return componente.vencido;
}

export function checkEmpty(val: any): boolean {
    if (val != null) {
        return val.trim() == "";
    } else {
        return true;
    }
}